import React, { Component } from "react"
import Layout from "../components/layout"

class RegistrazioneAvvenuta extends Component {
  state = {}
  render() {
    return (
      <Layout>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className={"page-title"}>Complimenti!</h1>

              <p>
                La registrazione è avvenuta con successo!
                <br />
                Ora devi confermare la tua email.{" "}
                <strong>Controlla la casella di posta</strong>.
              </p>

              <p>
                <a href="/login" className="btn btn-primary">
                  Vai alla login
                </a>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RegistrazioneAvvenuta
